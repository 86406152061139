import "core-js/modules/es.array.concat";import "core-js/modules/es.array.for-each";import "core-js/modules/es.array.slice";import "core-js/modules/es.function.name";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.replace";import "core-js/modules/web.dom-collections.for-each";import _createForOfIteratorHelper from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";import _toConsumableArray from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/toConsumableArray";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import path from 'path';
import ScrollPane from './ScrollPane.vue';

export default {
  components: { ScrollPane: ScrollPane },
  data: function data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [] };

  },
  computed: {
    visitedViews: function visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    routes: function routes() {
      return this.$store.state.permission.routes;
    } },

  watch: {
    $route: function $route() {
      this.addTags();
      this.moveToCurrentTag();
    },
    visible: function visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    } },

  mounted: function mounted() {
    this.initTags();
    this.addTags();
  },
  methods: {
    isActive: function isActive(route) {
      var name = this.$route.meta.activeName || this.$route.name;

      return route.name === name;
      // return route.path === this.$route.path;
    },
    filterAffixTags: function filterAffixTags(routes) {var _this = this;var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var tags = [];

      routes.forEach(function (route) {
        if (route.meta && route.meta.affix) {
          var tagPath = path.resolve(basePath, route.path);

          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: _objectSpread({}, route.meta),
            matched: route.matched });

        }
        if (route.children) {
          var tempTags = _this.filterAffixTags(route.children, route.path);

          if (tempTags.length >= 1) {
            tags = [].concat(_toConsumableArray(tags), _toConsumableArray(tempTags));
          }
        }
      });
      return tags;
    },
    initTags: function initTags() {
      this.affixTags = this.filterAffixTags(this.routes);var _iterator = _createForOfIteratorHelper(
      this.affixTags),_step;try {for (_iterator.s(); !(_step = _iterator.n()).done;) {var tag = _step.value;
          // Must have tag name
          if (tag.name) {
            this.$store.dispatch('tagsView/addView', tag);
          }
        }} catch (err) {_iterator.e(err);} finally {_iterator.f();}
    },
    addTags: function addTags() {
      var route = this.getActiveRoute(this.$route);var
      name = route.name;

      if (name) {
        this.$store.dispatch('tagsView/addView', route);
      }
      return false;
    },
    getActiveRoute: function getActiveRoute(route) {var
      matched = route.matched,meta = route.meta,parent = route.parent;

      if (matched) {
        var len = matched.length;

        if (len < 2) {
          return route;
        }
        if (meta.parent) {
          return this.getActiveRoute(matched[len - 1]);
        }
        return route;
      }
      if (meta.parent) {
        return this.getActiveRoute(parent);
      }
      return route;
    },
    moveToCurrentTag: function moveToCurrentTag() {var _this2 = this;
      var tags = this.$refs.tag;

      this.$nextTick(function () {var _iterator2 = _createForOfIteratorHelper(
        tags),_step2;try {for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {var tag = _step2.value;
            if (tag.to.path === _this2.$route.path) {
              _this2.$refs.scrollPane.moveToTarget(tag);
              break;
            }
          }} catch (err) {_iterator2.e(err);} finally {_iterator2.f();}
      });
    },
    refreshSelectedTag: function refreshSelectedTag(view) {var _this3 = this;
      this.$store.dispatch('tagsView/refreshView', view).then(function () {var
        fullPath = view.fullPath;

        _this3.$nextTick(function () {
          _this3.$router.replace({
            path: '/redirect' + fullPath });

        });
      });
    },
    closeSelectedTag: function closeSelectedTag(view) {var _this4 = this;
      if (view.meta && view.meta.affix) {
        return;
      }
      this.$store.dispatch('tagsView/delView', view).then(function (_ref) {var visitedViews = _ref.visitedViews;
        if (_this4.isActive(view)) {
          _this4.toLastView(visitedViews, view);
        }
      });
    },
    closeOthersTags: function closeOthersTags() {var _this5 = this;
      this.$router.push(this.selectedTag);
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(function () {
        _this5.moveToCurrentTag();
      });
    },
    closeAllTags: function closeAllTags(view) {var _this6 = this;
      this.$store.dispatch('tagsView/delOthersViews').then(function (_ref2) {var visitedViews = _ref2.visitedViews;
        _this6.toLastView(visitedViews, view);
      });
    },
    toLastView: function toLastView(visitedViews, view) {
      var latestView = visitedViews.slice(-1)[0];

      if (latestView) {
        this.$router.push(latestView);
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        // 默认 首页路由
      } else if (view.name === 'instrument') {
        // to reload home page
        this.$router.replace({ path: '/redirect' + view.fullPath });
      } else {
        this.$router.push('/');
      }
    },
    openMenu: function openMenu(tag, e) {
      // const menuMinWidth = 105
      // const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      // const { offsetWidth } = this.$el // container width
      // const maxLeft = offsetWidth - menuMinWidth // left boundary
      // const left = e.clientX - offsetLeft + 15 // 15: margin right

      // if (left > maxLeft) {
      //   this.left = maxLeft
      // } else {
      //   this.left = left
      // }
      // this.top = e.clientY
      // this.visible = true
      // this.selectedTag = tag
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    } } };